import useSettings from './modules/settings.module';

const { getTranslation, getTcTranslation } = useSettings();

export const DEBOUNCE_TIME = 700;
export const SCROLLING_DURATION = 1500;
export const SORTING_DESC_TYPE = 'DESC';
export const SORTING_ASC_TYPE = 'ASC';
export const DONATE_NOW = 'N';
export const DONATE_BEFORE_EXPIRATION = 'B';
export const UNKNOWN_STRING_VALUE = 'UNKNOWN';
export const MONTH_YEAR_DATE = 'MM-YYYY';
export const DAY_MONTH_YEAR_DATE = 'DD-MM-YYYY';
export const PILLS_PER_BOX = 28;
export const UNKNOWN_MEDS_UNIT_PRICE = 10.5;
export const DEFAULT_PLURAL_VALUE = 2;

export const DATA_TABLE_FOOTER_PAGINATION = {
    disableItemsPerPage: true,
    itemsPerPageText: getTranslation('labels.itemsPerPage'),
    showCurrentPage: true,
    showFirstLastPage: true,
    // @ts-ignore
    itemsPerPageOptions: [parseInt(process.env.VUE_APP_PAGINATION_PER_PAGE), -1],
};

export const ICONS = Object.freeze({
    download: 'mdi-download',
    chevronDown: 'mdi-chevron-down',
    chevronUp: 'mdi-chevron-up',
    plusCircle: 'mdi-plus-circle',
    info: 'mdi-information',
    deleteCircle: 'mdi-delete-circle',
    close: 'mdi-close',
    checkCircle: 'mdi-check-circle',
    pencil: 'mdi-pencil',
    pencilCircle: 'mdi-pencil-circle',
    shopping: 'mdi-shopping',
    cashMultiple: 'mdi-cash-multiple',
    publicBenefitEntities: 'mdi-home-group',
    hospital: 'mdi-hospital-building',
    hospitalBox: 'mdi-hospital-box',
    truck: 'mdi-truck',
    truckPlus: 'mdi-truck-plus',
    magnify: 'mdi-magnify',
    calendar: 'mdi-calendar',
    magnifyScan: 'mdi-magnify-scan',
    phone: 'mdi-phone',
    map: 'mdi-google-maps',
    comment: 'mdi-comment',
    eye: 'mdi-eye',
    eyeOff: 'mdi-eye-off',
    arrowLeft: 'mdi-arrow-left',
    arrowDown: 'mdi-arrow-down',
    meds: 'mdi-pill',
    hygProds: 'mdi-hospital-box-outline',
    lock: 'mdi-lock',
    bookMultiple: 'mdi-book-multiple',
    handHeart: 'mdi-hand-heart',
    help: 'mdi-help-circle',
    account: 'mdi-account',
    cart: 'mdi-cart',
    dots: 'mdi-dots-vertical',
    logout: 'mdi-logout',
});

export const ORG_TYPES = Object.freeze({
    Open: 'O',
    Close: 'C',
    Other: 'A',
});

export const BACKEND_PROPERTIES = Object.freeze({
    med: 'med_name',
    hygProd: 'hyg_prod_name',
    substances: 'substances',
    category: 'category_presentation',
    quantity: 'quantity',
    toDonate: 'to_donate',
    dateUpdated: 'date_updated',
    state: 'state',
    delete: 'delete',
    expirationDate: 'expiration_date',
    company: 'company',
    name: 'name',
    availableQuantity: 'available_quantity',
    donator: 'organization',
});

export const MEASUREMENT_UNIT = Object.freeze({
    Box: 'B',
    Pill: 'P',
});

export const QUANTITY_TYPES = Object.freeze({
    Box: 'BOX',
    Bag: 'BAG',
    Piece: 'PIECE',
    Ml: 'ML',
    Other: 'OTHER',
});

export const CON_FORM_TYPES = Object.freeze({
    Capsules: 'CAPSULES',
    Ampules: 'AMPULES',
    Tablets: 'TABLETS',
    Syrup: 'SYRUP',
    Cream: 'CREAM',
    Col: 'COL',
    Inhaler: 'INHALER',
    Gel: 'GEL',
    Injection: 'INJECTION',
    Sach: 'SACH',
    NasalSpray: 'NASAL_SPRAY',
    Other: 'OTHER',
});

export const CON_UNIT_TYPES = Object.freeze({
    ml: 'ML',
    mg: 'MG',
    g: 'G',
    mcg: 'MCG',
    percent: '%',
    Other: 'OTHER',
});

export const DONATION_STATUS = Object.freeze({
    Basket: 'BASKET',
    Preparation: 'PREPARATION',
    OnTheWay: 'ON_THE_WAY',
    Completed: 'COMPLETED',
});

export const TRANSPORT_METHOD = Object.freeze({
    PickUp: 'PICK_UP',
    OwnCourier: 'OWN_COURIER',
    AcsCourier: 'GIVMED_ACS_COURIER',
});

export const ORDER_TYPE = Object.freeze({
    Meds: 'MEDS',
    HygProds: 'HYG_PRODS',
});

export const ROLE = Object.freeze({
    Beneficiary: 'BENEFICIARY',
    Donator: 'DONATOR',
});

export const ORDER_PAGES = Object.freeze({
    Basket: 'BASKET',
    Incoming_pending_orders_as_beneficiary: 'INCOMING_PENDING_ORDERS_AS_BENEFICIARY',
    Outgoing_pending_orders_as_donator: 'OUTGOING_PENDING_ORDERS_AS_DONATOR',
    Incoming_history_orders_as_beneficiary: 'INCOMING_HISTORY_ORDERS_AS_BENEFICIARY',
    Outgoing_history_orders_as_donator: 'OUTGOING_HISTORY_ORDERS_AS_DONATOR',
});

export const MAP_ORDER_PAGES_TO_URL = new Map([
    [ORDER_PAGES.Basket, '/basket-donation-orders'],
    [ORDER_PAGES.Incoming_pending_orders_as_beneficiary, `/preparation-and-on-the-way-donation-orders?role=${ROLE.Beneficiary}`],
    [ORDER_PAGES.Outgoing_pending_orders_as_donator, `/preparation-and-on-the-way-donation-orders?role=${ROLE.Donator}`],
    [ORDER_PAGES.Incoming_history_orders_as_beneficiary, `/completed-donation-orders?role=${ROLE.Beneficiary}`],
    [ORDER_PAGES.Outgoing_history_orders_as_donator, `/completed-donation-orders?role=${ROLE.Donator}`],
]);

export const SORTING_CHOICES = new Map([
    [BACKEND_PROPERTIES.med, 'NAME'],
    [BACKEND_PROPERTIES.hygProd, 'NAME'],
    [BACKEND_PROPERTIES.substances, 'SUBSTANCE'],
    [BACKEND_PROPERTIES.category, 'CATEGORY'],
    [BACKEND_PROPERTIES.quantity, 'QUANTITY'],
    [BACKEND_PROPERTIES.toDonate, 'TO_DONATE'],
    [BACKEND_PROPERTIES.expirationDate, 'EXP_DATE'],
    [ROLE.Donator, ROLE.Donator],
    [ROLE.Beneficiary, ROLE.Beneficiary],
]);

export const SORTING_TYPES = new Map([
    [undefined, 'DESC'],
    [true, 'DESC'],
    [false, 'ASC'],
]);

export const DONATION_TYPES = new Map([
    [DONATE_NOW, true],
    [DONATE_BEFORE_EXPIRATION, false],
]);

export const MEASUREMENT_UNITS_TOTAL_QUANTITIES_MAP = new Map([
    [MEASUREMENT_UNIT.Box, 'total_boxes'],
    [MEASUREMENT_UNIT.Pill, 'total_pills'],
]);

export const MEASUREMENT_UNITS_TOTAL_AVAILABLE_QUANTITIES_MAP = new Map([
    [MEASUREMENT_UNIT.Box, 'total_available_boxes'],
    [MEASUREMENT_UNIT.Pill, 'total_available_pills'],
]);

const TOTAL_BOXES_QUANTITY = 'total_boxes';
const TOTAL_PIECES_QUANTITY = 'total_pieces';
const TOTAL_OTHER_QUANTITY = 'total_other';

export const QUANTITY_TYPES_TOTAL_QUANTITIES_MAP = new Map([
    [QUANTITY_TYPES.Box, TOTAL_BOXES_QUANTITY],
    [QUANTITY_TYPES.Piece, TOTAL_PIECES_QUANTITY],
    [QUANTITY_TYPES.Other, TOTAL_OTHER_QUANTITY],
]);

export const TOTAL_QUANTITIES_QUANTITY_TYPE_MAP = new Map([
    [TOTAL_BOXES_QUANTITY, QUANTITY_TYPES.Box],
    [TOTAL_PIECES_QUANTITY, QUANTITY_TYPES.Piece],
    [TOTAL_OTHER_QUANTITY, QUANTITY_TYPES.Other],
]);

const TOTAL_AVAILABLE_BOXES_QUANTITY = 'total_available_boxes';
const TOTAL_AVAILABLE_PIECES_QUANTITY = 'total_available_pieces';
const TOTAL_AVAILABLE_OTHER_QUANTITY = 'total_available_other';

export const QUANTITY_TYPES_TOTAL_AVAILABLE_QUANTITIES_MAP = new Map([
    [QUANTITY_TYPES.Box, TOTAL_AVAILABLE_BOXES_QUANTITY],
    [QUANTITY_TYPES.Piece, TOTAL_AVAILABLE_PIECES_QUANTITY],
    [QUANTITY_TYPES.Other, TOTAL_AVAILABLE_OTHER_QUANTITY],
]);

export const TOTAL_AVAILABLE_QUANTITIES_QUANTITY_TYPE_MAP = new Map([
    [TOTAL_AVAILABLE_BOXES_QUANTITY, QUANTITY_TYPES.Box],
    [TOTAL_AVAILABLE_PIECES_QUANTITY, QUANTITY_TYPES.Piece],
    [TOTAL_AVAILABLE_OTHER_QUANTITY, QUANTITY_TYPES.Other],
]);

export const getInitialSortingData = () => {
    return {
        /** @type {string | null | undefined} */
        sort_by: null,
        sort_type: SORTING_TYPES.get(true),
    };
};

export const TRANSPORT_METHODS_DROPDOWN = [
    {
        text: getTranslation('transportMethods.PICK_UP'),
        value: TRANSPORT_METHOD.PickUp,
    },
    {
        text: getTranslation('transportMethods.OWN_COURIER'),
        value: TRANSPORT_METHOD.OwnCourier,
    },
    {
        text: getTranslation('transportMethods.GIVMED_ACS_COURIER'),
        value: TRANSPORT_METHOD.AcsCourier,
    },
];

export const MEASUREMENT_UNIT_DROPDOWN = [
    {
        text: getTcTranslation('measurementUnit.B', DEFAULT_PLURAL_VALUE),
        value: MEASUREMENT_UNIT.Box,
    },
    {
        text: getTcTranslation('measurementUnit.P', DEFAULT_PLURAL_VALUE),
        value: MEASUREMENT_UNIT.Pill,
    },
];

export const QUANTITY_TYPES_DROPDOWN = [
    {
        text: getTcTranslation('quantityType.BOX', DEFAULT_PLURAL_VALUE),
        value: QUANTITY_TYPES.Box,
    },
    // {
    //     text: getTcTranslation('quantityType.BAG', DEFAULT_PLURAL_VALUE),
    //     value: QUANTITY_TYPES.Bag,
    // },
    {
        text: getTcTranslation('quantityType.PIECE', DEFAULT_PLURAL_VALUE),
        value: QUANTITY_TYPES.Piece,
    },
    // {
    //     text: getTcTranslation('quantityType.ML', DEFAULT_PLURAL_VALUE),
    //     value: QUANTITY_TYPES.Ml,
    // },
    {
        text: getTcTranslation('quantityType.OTHER', DEFAULT_PLURAL_VALUE),
        value: QUANTITY_TYPES.Other,
    },
];

export const CON_FORM_TYPES_DROPDOWN = [
    {
        text: getTranslation('conFormType.CAPSULES'),
        value: CON_FORM_TYPES.Capsules,
    },
    {
        text: getTranslation('conFormType.AMPULES'),
        value: CON_FORM_TYPES.Ampules,
    },
    {
        text: getTranslation('conFormType.TABLETS'),
        value: CON_FORM_TYPES.Tablets,
    },
    {
        text: getTranslation('conFormType.SYRUP'),
        value: CON_FORM_TYPES.Syrup,
    },
    {
        text: getTranslation('conFormType.CREAM'),
        value: CON_FORM_TYPES.Cream,
    },
    {
        text: getTranslation('conFormType.COL'),
        value: CON_FORM_TYPES.Col,
    },
    {
        text: getTranslation('conFormType.INHALER'),
        value: CON_FORM_TYPES.Inhaler,
    },
    {
        text: getTranslation('conFormType.GEL'),
        value: CON_FORM_TYPES.Gel,
    },
    {
        text: getTranslation('conFormType.INJECTION'),
        value: CON_FORM_TYPES.Injection,
    },
    {
        text: getTranslation('conFormType.SACH'),
        value: CON_FORM_TYPES.Sach,
    },
    {
        text: getTranslation('conFormType.NASAL_SPRAY'),
        value: CON_FORM_TYPES.NasalSpray,
    },
    {
        text: getTranslation('conFormType.OTHER'),
        value: CON_FORM_TYPES.Other,
    },
];

export const CON_UNIT_TYPES_DROPDOWN = [
    {
        text: getTranslation('conUnitType.ml'),
        value: CON_UNIT_TYPES.ml,
    },
    {
        text: getTranslation('conUnitType.mg'),
        value: CON_UNIT_TYPES.mg,
    },
    {
        text: getTranslation('conUnitType.g'),
        value: CON_UNIT_TYPES.g,
    },
    {
        text: getTranslation('conUnitType.mcg'),
        value: CON_UNIT_TYPES.mcg,
    },
    {
        text: getTranslation('conUnitType.percent'),
        value: CON_UNIT_TYPES.percent,
    },
    {
        text: getTranslation('conUnitType.Other'),
        value: CON_UNIT_TYPES.Other,
    },
];

export const MEDS_INFO_TABLE = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.med,
    },
    {
        text: getTranslation('storage.headers.substance'),
        value: BACKEND_PROPERTIES.substances,
    },
];

export const HYG_PRODS_INFO_TABLE = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.name,
    },
    {
        text: getTranslation('storage.headers.category'),
        value: BACKEND_PROPERTIES.category,
    },
    {
        text: getTranslation('storage.headers.company'),
        value: BACKEND_PROPERTIES.company,
    },
];

export const STORAGE_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.hygProd,
    },
    {
        text: getTranslation('storage.headers.category'),
        value: BACKEND_PROPERTIES.category,
    },
    {
        text: getTranslation('storage.headers.quantity'),
        value: BACKEND_PROPERTIES.quantity,
    },
    {
        text: getTranslation('storage.headers.toDonate'),
        value: BACKEND_PROPERTIES.toDonate,
    },
    {
        text: getTranslation('storage.headers.expirationDate'),
        value: BACKEND_PROPERTIES.expirationDate,
    },
    {
        text: getTranslation('storage.headers.state'),
        value: BACKEND_PROPERTIES.state,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.delete'),
        value: BACKEND_PROPERTIES.delete,
        sortable: false,
    },
];

export const STORAGE_MEDS_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.med,
    },
    {
        text: getTranslation('storage.headers.substance'),
        value: BACKEND_PROPERTIES.substances,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.quantity'),
        value: BACKEND_PROPERTIES.quantity,
    },
    {
        text: getTranslation('storage.headers.toDonate'),
        value: BACKEND_PROPERTIES.toDonate,
    },
    {
        text: getTranslation('storage.headers.expirationDate'),
        value: BACKEND_PROPERTIES.expirationDate,
    },
    {
        text: getTranslation('storage.headers.delete'),
        value: BACKEND_PROPERTIES.delete,
        sortable: false,
    },
];

export const MEDS_NEEDS_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.med,
    },
    {
        text: getTranslation('storage.headers.substance'),
        value: BACKEND_PROPERTIES.substances,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.quantity'),
        value: BACKEND_PROPERTIES.quantity,
    },
    {
        text: getTranslation('storage.headers.dateUpdated'),
        value: BACKEND_PROPERTIES.dateUpdated,
    },
    {
        text: getTranslation('storage.headers.delete'),
        value: BACKEND_PROPERTIES.delete,
        sortable: false,
    },
];

export const HYG_PRODS_NEEDS_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.name'),
        value: BACKEND_PROPERTIES.hygProd,
    },
    {
        text: getTranslation('storage.headers.category'),
        value: BACKEND_PROPERTIES.category,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.quantity'),
        value: BACKEND_PROPERTIES.quantity,
    },
    {
        text: getTranslation('storage.headers.dateUpdated'),
        value: BACKEND_PROPERTIES.dateUpdated,
    },
    {
        text: getTranslation('storage.headers.delete'),
        value: BACKEND_PROPERTIES.delete,
        sortable: false,
    },
];

export const ABSTRACT_TO_ORDER_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.availableQuantity'),
        value: BACKEND_PROPERTIES.availableQuantity,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.expirationDate'),
        value: BACKEND_PROPERTIES.expirationDate,
        sortable: false,
    },
    {
        text: getTranslation('storage.headers.donator'),
        value: BACKEND_PROPERTIES.donator,
        sortable: false,
    },
];

export const MEDS_TO_ORDER_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.medName'),
        value: BACKEND_PROPERTIES.med,
    },
    {
        text: getTranslation('storage.headers.substance'),
        value: BACKEND_PROPERTIES.substances,
        sortable: false,
    },
// @ts-ignore
].concat(ABSTRACT_TO_ORDER_TABLE_HEADERS);

export const HYG_PRODS_TO_ORDER_TABLE_HEADERS = [
    {
        text: getTranslation('storage.headers.hygProdName'),
        value: BACKEND_PROPERTIES.hygProd,
    },
    {
        text: getTranslation('storage.headers.category'),
        value: BACKEND_PROPERTIES.category,
    },
// @ts-ignore
].concat(ABSTRACT_TO_ORDER_TABLE_HEADERS);

export const DOWNLOAD_FILE_FORMATS = [
    'csv', 'xlsx',
];
